import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import cx from "classnames";
import SlideToggle from "react-slide-toggle";

import hamburgerStyles from "../css/hamburger.module.css";

const Nav = () => {
  const data = useStaticQuery(graphql`
    query NavQuery {
      prismicGeneral {
        data {
          nav {
            link {
              url
            }
            text
          }
        }
      }
    }
  `);

  return (
    <>
      <nav className="hidden lg:flex fixed h-full right-0 flex-col text-right justify-center items-center pr-3 md:pr-5">
        {data.prismicGeneral.data.nav.map(({ link, text }) => (
          <Link
            key={text}
            to={link.url}
            className="text-4xl opacity-30 hover:opacity-100 text-dark w-64 leading-none pb-6  transition-all duration-150"
          >
            {text}
          </Link>
        ))}
      </nav>

      <nav className="lg:hidden sticky top-0 z-10 bg-light bg-opacity-90">
        <SlideToggle
          duration={300}
          collapsed={true}
          render={({ toggle, setCollapsibleElement, progress }) => (
            <>
              <button
                onClick={toggle}
                className="transition duration-150 focus:outline-none absolute right-0 p-4"
              >
                <span className="inline-block relative h-3 w-5">
                  <span
                    className={cx(
                      hamburgerStyles.inner,
                      "transition-all duration-200 ease-in-out bg-dark block",
                      {
                        [hamburgerStyles.active]: progress
                      }
                    )}
                  />
                </span>
              </button>
              <div ref={setCollapsibleElement} className="text-right p-4 mt-10">
                <ul>
                  {data.prismicGeneral.data.nav.map(({ link, text }) => (
                    <li key={text}>
                      <Link
                        to={link.url}
                        className="pb-4 block text-xl md:text-2xl transition-all duration-150"
                        onClick={() => toggle()}
                      >
                        {text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        />
      </nav>
    </>
  );
};
export default Nav;
