import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

import Header from "./header";
import Nav from "./nav";

function Layout({ children }) {
  return (
    <div
      className="flex flex-col min-h-screen text-dark bg-light font-sans relative"
      id="spencer"
    >
      <Header />
      <Nav />
      <main className="flex flex-col flex-1 md:justify-center w-full">
        {children}
      </main>
      <Link
        to="/info"
        className="text-dark opacity-30 hover:opacity-100 text-xl block fixed right-0 bottom-0 p-3 md:p-5"
      >
        Info
      </Link>
      <div
        className="p-3 md:p-5 bottom-0 left-0 fixed -mb-12 md:-mb-16"
        style={{
          transform: "rotate(270deg)",
          transformOrigin: "left top"
        }}
      >
      </div>
    </div>

  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
