import { graphql, Link } from "gatsby";
import React from "react";

const Header = () => (
  <div className="p-3 md:p-5 fixed">
    <Link to="/">
      <span className="text-2xl">Spencer Wells</span>
    </Link>
  </div>
);

export default Header;
